import React, { useRef } from 'react';
import './App.css';
import TypeWriter from 'typewriter-effect';
import { Squircle } from '@squircle-js/react';
import { FaGithub } from 'react-icons/fa6';
import { gsap, MotionPathPlugin } from 'gsap/all';
import { useGSAP } from '@gsap/react';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { AdvancedImage } from '@cloudinary/react';

function App() {
  const animation = useRef()

  const pfp = new CloudinaryImage("portfolio/pfp", { cloudName: process.env.REACT_APP_CLOUD_NAME })

  useGSAP(() => {
    let ctx = gsap.context(() => {
      let logo = (document.querySelector('.logo'))
      
      const transition = gsap.timeline({ paused: true })
      const logoUpDown = gsap.timeline({ repeat: -1,  })
      gsap.registerPlugin(MotionPathPlugin)

      logoUpDown
        .to(logo, { y: 35, rotate: -15, duration: 1.5, ease: 'none' })
        .to(logo, { y: -35, rotate: -20, duration: 1.5, ease: 'none' })
        .to(logo, { y: 0, rotate: 0, duration: 1.5, ease: 'none'})
        .to(logo, { y: 35, rotate: 15, duration: 1.5, ease: 'none' })
        .to(logo, { y: -35, rotate: 20, duration: 1.5, ease: 'none' })
        .to(logo, { y: 0, rotate: 0, duration: 1.5, ease: 'none' })

      transition
        .to(logo, { x: 1000, duration: 1, ease: 'none' })
        .to(logo, { x: -1000, duration: 1, ease: 'none' })
        .to(logo, { x: 0, duration: 1, ease: 'none' })
    }, animation)

    return () => ctx.revert()
  }, [])

  return (
    <>
      <div className="Home">
        <div className="content">
          <div className="text">
            <h1> 
              Hi, I'm <mark> bagaco </mark>
              <TypeWriter options={{strings: ['Freelancer / Software Developer'], 
                autoStart: true, loop: true, delay: 200}} />

              <p onClick={ console.log("test") } > Click here to explore my portfolio! </p>
            </h1>
          </div>
          <div className="logo" ref={animation}>
            <Squircle cornerRadius={50} cornerSmoothing={1} width={300} height={300} className='logo-squircle'>
              <AdvancedImage cldImg={pfp} />
            </Squircle>
          </div>
        </div>
      </div>

      <footer> 
        <p> Copyright © 2024 bagaco. All rights reserved. </p>
        <a href="https://github.com/bagacopt" target="_blank" rel='noreferrer noopener'>
          <FaGithub className='icon' size={32} color='lightgray' />
        </a>
      </footer>
    </>
  )
}

export default App;